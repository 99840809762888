<template>
  <div>
    <AuthNavbar/>
    <CCard class="border-0 w-25 mt-5 mx-auto" v-if="$route.params.status === 'success'">
      <h1 class="text-main_black font-2xl font-weight-normal">Password recovery</h1>
      <CCardBody class="text-center w-75 m-auto">
        <img src="@/assets/icons/ic_success.svg" alt="">
        <h3 class="font-lg font-weight-normal text-main_black mt-2">Congratulations!</h3>
        <p class="text-gray font-weight-normal">
          A link to reset your password has been sent to your email.
        </p>
      </CCardBody>
    </CCard>
    <CCard class="border-0 w-25 mx-auto mt-5" v-if="$route.params.status === 'error'">
      <h1 class="text-main_black font-2xl font-weight-normal">Password recovery</h1>
      <CCardBody class="text-center w-100 m-auto">
        <img src="@/assets/icons/ic_fail.svg" alt="">
        <h3 class="font-lg font-weight-normal text-main_black mt-2">Your account is temporarily blocked!</h3>
        <p class="text-gray font-weight-normal">
          We ask you to contact support at the address gteleuov@gmail.com or phone number +7(707)000-00-00
        </p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import AuthNavbar from '../base/AuthNavbar';

export default {
  name: "Success.vue",
  props:[
      'isError'
  ],
  mounted() {
  },
  components: {AuthNavbar}
}
</script>

<style scoped>

</style>